
import {MenuOption, MenuButtonOption, RoutingValue} from './types'
import {ContentFsm} from './const'
import {env, domain, sitestatus} from './config'

import {Home} from './Home'
import {HomeAuth} from './HomeAuth'
import {SignIn, WebAuthList, WebAuthAdd, WebAuthDeleteAll} from './SignIn'
import {SignOut} from './SignOut'
import {Catchall} from './Catchall'

import {Order, Optin} from './FormsWrapper'
import {OrderSuccess} from './OrderSuccess'
import {OrderCancel} from './OrderCancel'
import {About} from './About'
import {Contact} from './Contact'
import {Terms} from './Terms'
import {Support} from './Support'
import {Privacy} from './Privacy'
import {Maintenance} from './Maintenance'
import {OrderFail} from './OrderFail'
import {Faq} from './Faq'
import {Pages} from './Pages'
import {GotoUrl} from './GotoUrl'



export type routingValues = 'private' | 'maintenance' | 'degraded' | 'unauth' | 'auth' | 'revalidate';


export interface rootsOptions {
  private:        RoutingValue[],
  maintenance:    RoutingValue[],
  degraded:       RoutingValue[],
  unauth:         RoutingValue[],
  auth:           RoutingValue[],
  revalidate:     RoutingValue[]
}


export interface menuOptions {
  private:        MenuOption[],
  maintenance:    MenuOption[],
  degraded:       MenuOption[],
  unauth:         MenuOption[],
  auth:           MenuOption[],
  revalidate:     MenuOption[]
}




// This module is all about the menu options available for authenticated and unauthenticted users
// See Layout.tsx for how it is presented
     
// MENUS



export const footerOptions :MenuOption[] = [
                                      {path: '/about', title: 'About Us'},
                                      {path: '/contact', title: 'Contact'},
                                      {path: '/faq', title: 'FAQs'},
                                      {path: '/terms', title: 'Terms & Conditions'},
                                      {path: '/support', title: 'Support'},
                                      {path: '/privacy', title: 'Privacy & Cookies'}
                                    ]

export const privateUnauthOptions:     MenuOption[] = [];
export const maintenanceUnauthOptions: MenuOption[] = [];                    


export const revalidateOptions:      MenuOption[] = []

export const unauthOptions:          MenuOption[] = [
                                      //PUBLIC
                                      {path: '/home', title: 'Home'},
                                      {path: '/order', title: 'Order a Report'},
                                      {path: '/sample', title: 'Order a FREE Sample Report'},
                               //       {path: '/optin', title: 'Offers & News'},
                                      {path: '/resources/', title: 'Resources'},
                                    ]    


export const authOptions:            MenuOption[] = [
                                      {path: '/home', title: 'Home'},
                                      {path: '/order',     title: 'Order a Report'},
                                      {path: '/sample', title: 'Order a FREE Sample Report'},
                                      {path: '/resources/', title: 'Resources'},
                                      {path: '/optin', title: 'Offers & News'},

                                      {path: '/admin', title: 'Admin ', children:[
                                        {path: '/auth/order', title: 'Order a FREE Report'},
                                        {path: '/auth/sandbox', title: 'SANDBOX (test)'},
                                      ]},

                                      {path: '/account', title: 'Account ', children:[
                                        {path: '/webauth/add', title: 'Add Web Auth'},
                                        {path: '/webauth/del', title: 'Delete All Web Auth'}
                                      ]}
                                     ]    

export const   menuButtonCancel:      MenuButtonOption  = {path: '/cancel',  title: 'Cancel',  variant: 'warning', state: ContentFsm.REVALIDATE}
export const   menuButtonSignin:      MenuButtonOption  = {path: '/signin',  title: 'Signin',  variant: 'primary', state: ContentFsm.SIGNING_IN}        
export const   menuButtonSignout:     MenuButtonOption  = {path: '/signout', title: 'Signout', variant: 'secondary', state: ContentFsm.SIGNING_OUT}         
 

// PERMITTED ROUTES (revalidate is limbo land between auth and unauth)

export const   revalidateRoutes:           RoutingValue[] = [
                                              {path:'*',          component: Catchall                       }
                                            ]

export const   privateUnauthRoutes:        RoutingValue[] = [
                                              {path: '/signin',  component: SignIn                          }, 
                                              {path: '*',         component: Pages, overidePath:  '/private'}                                                           
                                            ]     
  
export const   maintenanceUnauthRoutes:   RoutingValue[] = [
                                              {path: '/signin',  component: SignIn                             }, 
                                              {path: '*',        component: Pages, overidePath:  '/maintenance'}
                                            ]     
                                            
export const   unauthRoutes:               RoutingValue[] = [                                           
                                              {path: '/home',     component: Pages,  overidePath: '/home'},     
                                              {path: '/',         component: Pages,  overidePath: '/home'},     
                                              {path: '',          component: Pages,  overidePath: '/home'},  
                           
                                              {path: '/auth*',    component: Pages,  overidePath: '/home'},  // traps erroneous call                           
                                             
                                              {path: '/homegoal*', component: Pages,  overidePath: '/home'},  // traps erroneous call                           
                                             
                                              {path: '/reports/*', component: Pages,  overidePath: '/reports/expired'},  // traps timed out reports                       
                                          
                                              {path:'/resources/', component: GotoUrl, overidePath: `https://${domain}/resources/` },
  
                                              {path: '/order',    component: Order, overidePath: '/order',   api: {api: 'stripe/session/create/', auth: false, request: 'Landing', upSell: '',  chargeable: true }},     
                                              {path: '/sample',   component: Order, overidePath: '/sample',  api: {api: 'nocharge/', auth: false, request: 'Sample', upSell: '/order',  chargeable: false }},                   
                                              {path: '/optin',    component: Optin,  overidePath: '/optin',  api: {api: 'optin/', auth: false,  request: 'OptIn' }},  
                                                                         
  
                                              {path: '/signin',   component: SignIn                               },
                                              
                                              {path: '*',         component: Pages                                } // catches all remainig html pages
                                            ]

export const   degradedUnauthRoutes:        RoutingValue[] = [                                           
                                            {path: '/home',     component: Pages,  overidePath: '/home'},     
                                            {path: '/',         component: Pages,  overidePath: '/home'},     
                                            {path: '',          component: Pages,  overidePath: '/home'},  
                         
                                            {path: '/auth*',    component: Pages,  overidePath: '/home'},  // traps erroneous call                           
                                           
                                            {path: '/homegoal*', component: Pages,  overidePath: '/home'},  // traps erroneous call                           
                                           
                                            {path: '/reports/*', component: Pages,  overidePath: '/reports/expired'},  // traps timed out reports                       
                                        
                                            {path:'/resources/', component: GotoUrl, overidePath: `https://${domain}/resources/` },

                                            {path: '/order',  component:  Pages,  overidePath: '/degraded'},   // special case degraded service                       
                                            {path: '/sample', component:  Pages,  overidePath: '/degraded'},   // special case degraded service                                     
                                            {path: '/optin',  component: Pages,  overidePath:  '/degraded'},    // special case degraded service   
                                              
                                            {path: '/signin',   component: SignIn                               },
                                            
                                            {path: '*',         component: Pages                                } // catches all remainig html pages
                                          ]

export const   authRoutes:                 RoutingValue[] = [
                                            {path: '/home',     component: Pages,  overidePath: '/home'},     
                                            {path: '/',         component: Pages,  overidePath: '/home'},
                                            {path: '',          component: Pages,  overidePath: '/home'},
                                            
                                            {path:'/resources/', component: GotoUrl, overidePath: `https://${domain}/resources/`  },

                                            {path: '/sample',   component: Order, overidePath: '/sample',  api: {api: 'nocharge/', auth: false, request: 'Sample', upSell: '/order', chargeable: false }},                   
                                   
                                            {path: '/auth/sandbox',  component: Order,  overidePath: '/request', api: {api: 'auth/stripe/session/create/', auth: true, request: 'Sandbox', upSell: '', chargeable: false }},                   
                            
                                            {path: '/auth/order',    component: Order,  overidePath: '/request', api: {api: 'auth/stripe/session/create/', auth: true,  request: 'Landing', upSell: '', chargeable: false}},  
                                            {path: '/order',         component: Order,  overidePath: '/order',   api: {api: 'stripe/session/create/',      auth: false, request: 'Landing', upSell: '', chargeable: true }},     
                                           
                                            {path: '/optin',         component: Optin,  overidePath: '/optin',    api: {api: 'optin/', auth: false,  request: 'OptIn' }},  
                                       

                                            {path: '/webauth/add',   component: WebAuthAdd},   
                                            {path: '/webauth/del',   component: WebAuthDeleteAll},  

                                            {path: '/signout',  component: SignOut},
                                          

                                            {path: '/signin',   component: Pages,   overidePath: '/home'}, // traps erroneous call
                                            
                                            {path: '/reports/*', component: Pages,  overidePath: '/reports/expired'},  // traps timed out reports      

                                            {path: '*',         component: Pages                                 }  // catches all remainig html pages
                                          ]

export const roots:rootsOptions = {  
  private:        privateUnauthRoutes,
  maintenance:    maintenanceUnauthRoutes,
  degraded:       degradedUnauthRoutes,
  unauth:         unauthRoutes,
  auth:           authRoutes,
  revalidate:     revalidateRoutes
}                           

export const menus:menuOptions = {  
  private:        privateUnauthOptions,
  maintenance:    maintenanceUnauthOptions,
  degraded:       unauthOptions,
  unauth:         unauthOptions,
  auth:           authOptions,
  revalidate:     revalidateOptions
}

