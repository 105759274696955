import React, { useCallback } from 'react';


import {useEffect, useState} from 'react';

import { Button, Col, Form, Row, InputGroup, Container, Tab, Tabs } from 'react-bootstrap';
import { ApiPost } from './Api'
import { LoadingFsm } from './const'

import { UrlImage } from './UrlImage'

import { PagesModal } from './Pages'


import { Formik, useFormikContext } from 'formik';
import * as yup from 'yup';



import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { useLocation } from 'react-router-dom';




export const OrderForm = (props: {setRequest:any, chargeable:boolean}) => {
  const [url, setUrl] = useState('');
  const [urlVerified, setUrlVerified] = useState(false);
  const [token, setToken] = useState('');
  const [terms, setTerms] = useState(false);
  //const [values, setValues] = useState({});
  
  interface formValues {
    url:        string,
    name:       string,
    email:      string,
    terms:      boolean,
    optIn:      boolean,
    coupon:     string,
    couponName: string,
    recaptcha:  string
  }

  const [initialValues, setInitialValues] = useState({
    url:          'https://',
    name:         '',
    email:        '',
    terms:        false,
    optIn:        false,
    coupon:       '',
    couponName:   '',
    recaptcha:    ''
  });

//////////////

  const { executeRecaptcha } = useGoogleReCaptcha();

/////////////

  const location = useLocation();

  const handleReCaptchaVerify = useCallback(async (values:formValues) => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    values.recaptcha =  await executeRecaptcha('submit');

    props.setRequest(values);

  }, [executeRecaptcha]);

  
  useEffect(() => {
    var parameters = Object.fromEntries(new URLSearchParams(decodeURIComponent(location.search)));

    setInitialValues({
      url:           (parameters.hasOwnProperty('url'))        ?   parameters.url :   'https://',
      name:          (parameters.hasOwnProperty('name'))       ?   parameters.name:   '',
      email:         (parameters.hasOwnProperty('email'))      ?   parameters.email:  '',
      terms:        ((parameters.hasOwnProperty('terms'))     &&  (parameters.terms==='true')) ? true : false,
      optIn:        ((parameters.hasOwnProperty('optIn'))     &&  (parameters.optIn==='true')) ? true : false,
      coupon:        (parameters.hasOwnProperty('coupon'))     ?   parameters.coupon :      '',
      couponName:    (parameters.hasOwnProperty('couponName')) ?   parameters.couponName 
                                                               :  (parameters.hasOwnProperty('coupon')) ? parameters.coupon : '',
      recaptcha: ''
    });
    parameters.hasOwnProperty('url') && checkUrl(parameters.url);
    parameters = {};
  }, [props]);


  const checkUrl = (value:string) => {
    const host = value.trim();
    if (host.match(hostName)) {
      //const domain = value.match(domainName);
      //setUrl((domain === null) ? '' : domain[0]);
      setUrl(host);
    } else {
      setUrl('');
    }
  }


  const domainName  = /[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,63}/
  const hostName    = /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,63}\b([-a-zA-Z0-9@:%_\+.~#?&\/=]*)$/gm
  
  const schemaChargeable= yup.object().shape({
    tab: yup.string(),

    url:       yup.string().required('Please Enter your candidate Url').matches(hostName,'Name must be a valid URL name (e.g. https://example.com/landing)'),
    email:     yup.string().email('Enter Email valid format (e.g. John@example.com)').required('Please Enter your Email (e.g. John@example.com)'),
    terms:     yup.boolean().required().oneOf([true],'Terms & Conditions must be accepted to proceed'),
  });

  const schemaNonChargeable= yup.object().shape({
    tab: yup.string(),

    url:       yup.string().required('Please Enter your candidate Url').matches(hostName,'Name must be a valid URL name (e.g. https://example.com/landing)'),
    name:      yup.string().min(1,'Your Name must be at least 1 character long').required('Please Enter your Name (e.g. Jane Smith)'),
    email:     yup.string().email('Enter Email valid format (e.g. John@example.com)').required('Please Enter your Email (e.g. John@example.com)'),
    terms:     yup.boolean().required().oneOf([true],'Terms & Conditions must be accepted to proceed'),
  
  });
 
  console.debug('EntryForm', url, urlVerified);
  
  return (
  <>
       <Row>
           
        <Col sm={12} md={8}>

          <Formik
            validationSchema={(props.chargeable) ? schemaChargeable : schemaNonChargeable}
            validateOnBlur={false} 
            validateOnChange={false} 
       
            //validateOnMount={false}
            //onSubmit={console.log}
      
            initialValues={initialValues}
            enableReinitialize 

            //onSubmit={(values:any) => {props.parameters.thread.urls = [values.url]; props.setRequest(props.parameters)}}  
            
            onSubmit={(values:any) => {
              // assume all parameters are verfied need only to check the image is verified and add captcha
              if (urlVerified) {
                handleReCaptchaVerify(values);
                //props.setRequest(values);
                //setValues(values);
              }
            }} 
          >

            {({ handleSubmit, handleChange, setFieldValue, values, touched, errors, isValid, isValidating, isSubmitting }) => (       
              <Form noValidate onSubmit={handleSubmit} >


              <Row>
                <Form.Group as={Col} controlId="url">   
                  <Form.Label>URL to Analyse:</Form.Label>
                    <InputGroup>      
                      <Form.Control
                        type="text"
                        placeholder=""
                        name="url"
                        value= {values.url}
                        //onChange={handleChange}
                        onChange={(e) => { handleChange(e); checkUrl(e.target.value) }}
                        isInvalid={!!errors.url}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.url?.toString()}
                      </Form.Control.Feedback>
                    </InputGroup>
                </Form.Group>
              </Row>
              <br />
              
              { (props.chargeable) ? "" :
                <>
                <Row>
                  <Form.Group as={Col} controlId="name">   
                      <Form.Label>Your Name:</Form.Label>
                        <InputGroup>      
                          <Form.Control
                            type="text"
                            placeholder=""
                            name="name"
                            value= {values.name}
                            onChange={handleChange}
                            isInvalid={!!errors.name}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.name?.toString()}
                          </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                </Row>
                <br />
                </>
              }
              
       
              <Row>
                <Form.Group as={Col} controlId="email">   
                    <Form.Label>Your Email:</Form.Label>
                      <InputGroup>      
                        <Form.Control
                          type="text"
                          placeholder=""
                          name="email"
                          value= {values.email}
                          onChange={handleChange}
                          isInvalid={!!errors.email}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.email?.toString()}
                        </Form.Control.Feedback>
                      </InputGroup>
                  </Form.Group>
                </Row>
                <br />
            
              { (values.couponName==='') ? "" :
                  <>
                  <Row>
                  <Form.Group as={Col} controlId="couponName">   
                    <Form.Label>Coupon:</Form.Label>
                      <InputGroup>      
                        <Form.Control
                          type="text"
                          disabled
                          placeholder=""
                          name="couponName"
                          value= {values.couponName}
                          //onChange={handleChange}
                          onChange={handleChange}
                          isInvalid={!!errors.couponName}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.url?.toString()}
                        </Form.Control.Feedback>
                      </InputGroup>
                  </Form.Group>
                </Row>
                </>
              }
              <br />
                <Row>
                  <Form.Group as={Col} controlId="optIn"> 
                    <Form.Label>Opt In to receiving news & offers from us:</Form.Label>
                    <InputGroup>     
                      <Form.Check>
                        <Form.Check.Input 
                          type="checkbox"   
                          name="optIn"   
                          checked = {values.optIn}
                          onChange={handleChange}  
                          isInvalid={!!errors.optIn}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.optIn?.toString()}
                        </Form.Control.Feedback>
                      </Form.Check>
                    </InputGroup>              
                  </Form.Group>
                </Row>
                <br />
                
                <Row>
                  <Form.Group as={Col} controlId="terms"> 
                    <Form.Label>Accept Terms & Conditions:</Form.Label>
                    &nbsp;<a className={"link"} onClick={() => setTerms(true)}>click to view terms</a>
                    <InputGroup>     
                      <Form.Check>
                        <Form.Check.Input 
                          type="checkbox"   
                          name="terms"   
                          checked = {values.terms}  
                          onChange={handleChange}  
                          isInvalid={!!errors.terms}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.terms?.toString()}
                        </Form.Control.Feedback>
                      </Form.Check>
                    </InputGroup>              
                  </Form.Group>
                </Row>
                <br />
  
   
                <Button type="submit" className="submit_button">Get Your Report NOW</Button>
        
                {(terms) && <PagesModal url={'/terms'} setHidden={() => setTerms(false)}/>}
  
                <br />
              </Form>
            )}
          </Formik>
          <br />
        </Col>
        <Col>
            {(url.length) > 0 && <UrlImage domainOk={setUrlVerified} title={'Visual Check of URL'} url={url}/>}
        </Col>
      </Row>
      <Row>
        <br />
      </Row>
        
    </>

  );
}




export const OptInForm = (props: {setRequest:any}) => {
  const [url, setUrl] = useState('');
  const [token, setToken] = useState('');
  const [terms, setTerms] = useState(false);
  //const [values, setValues] = useState({});
  
  interface formValues {
    name:       string,
    email:      string,
    recaptcha:  string
  }

  const [initialValues, setInitialValues] = useState({
    name:         '',
    email:        '',
    recaptcha:    ''
  });

//////////////

  const { executeRecaptcha } = useGoogleReCaptcha();

/////////////

  const location = useLocation();

  const handleReCaptchaVerify = useCallback(async (values:formValues) => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    values.recaptcha =  await executeRecaptcha('submit');

    props.setRequest(values);

  }, [executeRecaptcha]);

  
  useEffect(() => {
    var parameters = Object.fromEntries(new URLSearchParams(decodeURIComponent(location.search)));

    setInitialValues({
      name:          (parameters.hasOwnProperty('name'))       ?   parameters.name:   '',
      email:         (parameters.hasOwnProperty('email'))      ?   parameters.email:  '',
      recaptcha: ''
    });
    parameters = {};
  }, [props]);


  const schema = yup.object().shape({
    tab: yup.string(),

    name:      yup.string().min(1,'Your Name must be at least 1 character long').required('Please Enter your Name (e.g. Jane Smith)'),
    email:     yup.string().email('Enter Email valid format (e.g. John@example.com)').required('Please Enter your Email (e.g. John@example.com)'),
   });
 
  
  return (
  <>
       <Row>
           
        <Col sm={12} md={8}>

          <Formik
            validationSchema={schema}
            validateOnBlur={false} 
            validateOnChange={false} 
       
            //validateOnMount={false}
            //onSubmit={console.log}
      
            initialValues={initialValues}
            enableReinitialize 

            //onSubmit={(values:any) => {props.parameters.thread.urls = [values.url]; props.setRequest(props.parameters)}}  
            
            onSubmit={(values:any) => {
              // assume all parameters are verfied need only to check the image is verified and add captcha
              
                handleReCaptchaVerify(values);
                //props.setRequest(values);
                //setValues(values);
              
            }} 
          >

            {({ handleSubmit, handleChange, setFieldValue, values, touched, errors, isValid, isValidating, isSubmitting }) => (       
              <Form noValidate onSubmit={handleSubmit} >


            
          
              <Row>
                <Form.Group as={Col} controlId="name">   
                    <Form.Label>Your Name:</Form.Label>
                      <InputGroup>      
                        <Form.Control
                          type="text"
                          placeholder=""
                          name="name"
                          value= {values.name}
                          onChange={handleChange}
                          isInvalid={!!errors.name}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.name?.toString()}
                        </Form.Control.Feedback>
                      </InputGroup>
                  </Form.Group>
              </Row>
              <br />
                   
              <Row>
                <Form.Group as={Col} controlId="email">   
                    <Form.Label>Your Email:</Form.Label>
                      <InputGroup>      
                        <Form.Control
                          type="text"
                          placeholder=""
                          name="email"
                          value= {values.email}
                          onChange={handleChange}
                          isInvalid={!!errors.email}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.email?.toString()}
                        </Form.Control.Feedback>
                      </InputGroup>
                  </Form.Group>
                </Row>
                <br />
            
         
                <Button type="submit" className="submit_button">Subscribe to Mailing List</Button>
        

  
                <br />
              </Form>
            )}
          </Formik>
          <br />
        </Col>
      </Row>
      <Row>
        <br />
      </Row>
        
    </>

  );
}
