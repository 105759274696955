import { Amplify, ResourcesConfig } from 'aws-amplify';
import { siteStatus } from './types';

const clientVersion = '1v1'

// reads the .env.local/dev/stage/prod file and creates the config for the environment

// this is used by the Amplify Library
export const awsconfig:ResourcesConfig =   {
    Auth: {
        Cognito: {
            userPoolClientId: process.env.REACT_APP_USERPOOLCLIENTID!,
            userPoolId:       process.env.REACT_APP_USERPOOLID!,
            loginWith: { // only need if we use hosted ui to login
                oauth: {
                    domain:          `auth.${process.env.REACT_APP_DOMAIN}`,
                    scopes:          ['openid', 'aws.cognito.signin.user.admin'],
                    redirectSignIn:  [`${process.env.REACT_APP_HOST}/`],
                    redirectSignOut: [`${process.env.REACT_APP_HOST}/`],
                    responseType:    'code',
                },
                email: true,
            }
        }
    },
    API: {
        REST: {
            [process.env.REACT_APP_APINAME!]: {
                region:  process.env.REACT_APP_REGION!,
                endpoint: process.env.REACT_APP_ENDPOINT!
            }
        }
    }
};




Amplify.configure(awsconfig);
console.debug('awsconfig', awsconfig);



export const apiName:string         = process.env.REACT_APP_APINAME!;

export const siteTitle:string       = process.env.REACT_APP_SITE_TITLE!;
export const env:string             = process.env.REACT_APP_ENV!;
export const environment:string     = process.env.REACT_APP_ENVIRONMENT!+' '+clientVersion;

export const region:string          = process.env.REACT_APP_REGION!;

export const logginglevel:string    = process.env.REACT_APP_LOGGING_LEVEL!;
export const recaptchakey:string    = process.env.REACT_APP_RECAPTCHA_KEY!;

export const host:string            = process.env.REACT_APP_HOST!;
export const domain:string          = process.env.REACT_APP_DOMAIN!;

export const isProd:boolean         = (env === "prod");

export const recaptchaKey           = process.env.REACT_APP_RECAPTCHA_KEY!;

export const sitestatus:siteStatus  = {status: 'undefined'}

function get_ga_clientid() {
    var cookie:any = {};
    document.cookie.split(';').forEach(function(el) {
      var splitCookie = el.split('=');
      var key = splitCookie[0].trim();
      var value = splitCookie[1];
      cookie[key] = value;
    });

    if (cookie["_ga"]) {
        const regEx = /\d*.\d*$/gm
        const match = cookie["_ga"].match(regEx);
        if (match !== null) {
            return match[0];
        }
    }
   
    return "";
}


export const ga4ClientId = get_ga_clientid();