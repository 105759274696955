
import { host, env, ga4ClientId } from './config'

import { useEffect, useState } from 'react';

import { Spinner } from 'react-bootstrap';
import { ApiPost } from './Api'
import { LoadingFsm } from './const'


import { apiRequest, apiMisc, apiRouting } from './types'

import { OrderForm, OptInForm } from './EntryForms'

import { Pages } from './Pages'

import {RoutingValueProps} from './types'


//import { SiMetrodeparis } from "react-icons/si";




export const Order = (props: RoutingValueProps) => {
  const [fsm, setFsm ] = useState(LoadingFsm.UNDEFINED);
  const [data, setData] = useState<any>({})
  const [defaultParameters, setDefaultParameters] = useState<any>({})

  const setFormResults = (results: any) => {

    console.log('setFormResults', results);

    const {url, name, email, terms, optIn, coupon, recaptcha} = results;


    const regEx  = /\/+$/ // trailing slashes regex
    
    
    //const parameters:any = {...defaultParameters[0]}; // zzzz only coded for one array item

    const parameters:any = {};

    // data from form
    //parameters.type         = props.props.skew.type;
    parameters.urls         = [url.trim().replace(regEx, '')];
    parameters.name         = name;
    parameters.email        = email;
    parameters.terms        = terms;
    parameters.optIn        = optIn;
    parameters.coupon       = coupon;
    parameters.recaptcha    = recaptcha;
    
    setData(parameters);
  }

  useEffect(() => {

  
  if (fsm === LoadingFsm.UNDEFINED) {
    setData({});
    setFsm(LoadingFsm.EMPTY);
  }
  
}, [fsm]);


  useEffect(() => {
      
      const fetchData = async () => {
   
     
         const propsPath:string = (props.hasOwnProperty('overidePath')) ? props.overidePath! : props.path;

          var routing:apiRouting = {
              cancelPath:  `${propsPath}/cancel`,
              successPath: `${propsPath}/success`,
              failPath:    `${propsPath}/fail`,
              host:         host
          } 
 

          //const regEx       = /\s*_ga\s*=/gm
          //const analytics   = (document.cookie.search(regEx) !== -1)
          
          const analytics   = ga4ClientId;

          var submissionData:apiRequest = { 
                                            routing:  routing, 
                                            misc:     { 
                                                        client:     env, 
                                                        upSell:     props.api?.upSell, 
                                                        analytics:  analytics
                                                      }, 
                                            request:  data, 
                                            name:     props.api!.request!
                                          };
          
          console.log('submissionData', props, JSON.stringify(submissionData, null, 2));

          //throw new Error(`Debug Break`);

          var post;
          post = await ApiPost(props.api!.api!, submissionData, props.api?.auth); 
          //console.log('post', post);

          //console.log('data', data);
          console.log('post', JSON.stringify(post, null, 2));

          if (post.hasOwnProperty('url')) {
            window.location.replace(post.url);
            //window.location.href = post.url;

            console.log('post', JSON.stringify(post, null, 2));
            //setResults(post);
            setFsm(LoadingFsm.FOUND); // never displays because of the above redirection
          }
          else {
            // failed
            window.location.replace(routing.failPath);
            
            setFsm(LoadingFsm.ERROR); // never displays because of the above redirection
          }
        };

      if ((fsm === LoadingFsm.EMPTY) && (Object.keys(data).length !== 0)) { //} && Object.keys(data).length !== 0)  {
        setFsm(LoadingFsm.INITIALISING);
        fetchData();
      }
  }, [data]);
  
  console.log('redraw', fsm, props);

  switch (fsm) {

    case LoadingFsm.EMPTY:
    
      return (
        <> 
            <Pages path={props.path}/>
            
            <OrderForm setRequest={setFormResults} chargeable={props.api?.chargeable!}/> 
            {(props.api?.chargeable) &&
              <div className="center">
                  <img className="stripe" src="/assets/images/stripe.png" alt="Stripe Payments" />
              </div>   
            }
        </>
      )
      break;
    case LoadingFsm.INITIALISING:
    case LoadingFsm.FOUND:
    case LoadingFsm.ERROR:
      return (
        <>
          <br />
          <div className={"d-flex justify-content-center"}>
              <Spinner style={{width: 20, height: 20}} animation="border"></Spinner>
          </div>
        </>
      )
      break;
    default:
      return null;
  }
}


export const Optin = (props: RoutingValueProps) => {
  const [fsm, setFsm ] = useState(LoadingFsm.UNDEFINED);
  const [data, setData] = useState<any>({})
  const [defaultParameters, setDefaultParameters] = useState<any>({})
  
  const setFormResults = (results: any) => {

    console.log('setFormResults', results);

    const {name, email, recaptcha} = results;


    const regEx  = /\/+$/ // trailing slashes regex
    
    
    //const parameters:any = {...defaultParameters[0]}; // zzzz only coded for one array item

    const parameters:any = {};

    // data from form
    parameters.name         = name;
    parameters.email        = email;
    parameters.recaptcha    = recaptcha;
    
    setData(parameters);
  }

  useEffect(() => {

  
  if (fsm === LoadingFsm.UNDEFINED) {
    setData({});
    setFsm(LoadingFsm.EMPTY);
  }
  
}, [fsm]);


  useEffect(() => {
      
      const fetchData = async () => {
   
     
         const propsPath:string = (props.hasOwnProperty('overidePath')) ? props.overidePath! : props.path;

          var routing:apiRouting = {
              cancelPath:  `${propsPath}/cancel`,
              successPath: `${propsPath}/success`,
              failPath:    `${propsPath}/fail`,
              host:         host
          } 


          //const regEx       = /\s*_ga\s*=/gm
          //const analytics   = (document.cookie.search(regEx) !== -1)

          const analytics   = ga4ClientId;
        
          var submissionData:apiRequest = { 
                                            routing:  routing, 
                                            misc:     { 
                                                        client:     env, 
                                                        upSell:     props.api?.upSell, 
                                                        analytics:  analytics
                                                      }, 
                                            request:  data, 
                                            name:     props.api!.request!
                                          };

          console.log('submissionData', props, JSON.stringify(submissionData, null, 2));

          //throw new Error(`Debug Break`);

          var post;
          post = await ApiPost(props.api!.api!, submissionData, props.api?.auth); 
          console.log('post', JSON.stringify(post, null, 2));
       
          //throw new Error(`Debug Break`);

          if (post.hasOwnProperty('url')) {
            window.location.replace(post.url);
            //window.location.href = post.url;

            console.log('post', JSON.stringify(post, null, 2));
            //setResults(post);
            setFsm(LoadingFsm.FOUND); // never displays because of the above redirection
          }
          else {
            // failed
            window.location.replace(routing.failPath);
            
            setFsm(LoadingFsm.ERROR); // never displays because of the above redirection
          }
        };

      if ((fsm === LoadingFsm.EMPTY) && (Object.keys(data).length !== 0)) { //} && Object.keys(data).length !== 0)  {
        setFsm(LoadingFsm.INITIALISING);
        fetchData();
      }
  }, [data]);
  
  console.log('redraw', fsm, props);

  switch (fsm) {

    case LoadingFsm.EMPTY:
    
      return (
        <> 
            <Pages path={props.path}/>
            
            <OptInForm setRequest={setFormResults}/> 
        </>
      )
      break;
    case LoadingFsm.INITIALISING:
    case LoadingFsm.FOUND:
    case LoadingFsm.ERROR:
      return (
        <>
          <br />
          <div className={"d-flex justify-content-center"}>
              <Spinner style={{width: 20, height: 20}} animation="border"></Spinner>
          </div>
        </>
      )
      break;
    default:
      return null;
  }
}

