import React from 'react';
import { host, env  } from './config'

import {useContext, useEffect, useState} from 'react';

import { Button, Col, Form, Row, InputGroup, Container, Tab, Tabs, Spinner } from 'react-bootstrap';
import { ApiPost } from './Api'
import { LoadingFsm } from './const'


import { EntryForm } from './EntryForm'

import { PagesMeta, Pages } from './Pages'

import {RoutingValueProps} from './types'

import { useLocation, useParams } from 'react-router-dom';

//import defaultParameters  from './data/defaultParameters.json'
//import defaultPaymentDataDev from './data/defaultPaymentDataDev.json'
//import defaultPaymentDataProd from './data/defaultPaymentDataProd.json'
//import defaultPaymentData from './data/defaultPaymentData.json'
//import defaultPrice from './data/defaultPricesCodes.json'

import { SiMetrodeparis } from "react-icons/si";


const isProd = (env === 'prod');

/*
const skewSelect = ( props:skewMatch ) => {

  const result = defaultPrice.find(({type, live, charge}) => (type === props.type) && (live === props.live) && (charge === props.charge));

  return result;
}

*/

// xxxx CHANGE LINE BELOW FOR test (launch) v order (stripe)
//export const Test = () => {
export const Order = (props: RoutingValueProps) => {
  const [fsm, setFsm ] = useState(LoadingFsm.UNDEFINED);
  const [data, setData] = useState<any>({})
  const [defaultParameters, setDefaultParameters] = useState<any>({})
  //const [results, setResults] = useState<any>();


  //const location = useLocation();
 
  //const parameters = Object.fromEntries(new URLSearchParams(location.search));



  //console.log('SEARCH', parameters);

  
  //var defaultParameters = {};
  
  const setFormResults = (results: any) => {

    console.log('setFormResults', results);

    const {url, name, email, terms, optIn, coupon, recaptcha} = results;


    const regEx  = /\/+$/ // trailing slashes regex
    
    
    //const parameters:any = {...defaultParameters[0]}; // zzzz only coded for one array item

    const parameters:any = {};

    // data from form
    //parameters.type         = props.props.skew.type;
    parameters.urls         = [url.trim().replace(regEx, '')];
    parameters.name         = name;
    parameters.email        = email;
    parameters.terms        = terms;
    parameters.optIn        = optIn;
    parameters.coupon       = coupon;
    parameters.recaptcha    = recaptcha;
    
    setData(parameters);
  }

  useEffect(() => {

  
  if (fsm === LoadingFsm.UNDEFINED) {
    setData({});
    setFsm(LoadingFsm.EMPTY);
  }
  
}, [fsm]);


  useEffect(() => {
      
      const fetchData = async () => {
   

     
         const propsPath:string = (props.hasOwnProperty('overidePath')) ? props.overidePath! : props.path;

          var paymentData:any = {
            "cancelPath":  `${propsPath}/cancel`,
            "successPath": `${propsPath}/success`,
            host:   host
          } 
        
          const failPath:string = `${propsPath}/fail`;

          var submissionData:any = { payment: paymentData, misc: {client: env, upSell: props.order?.upSell}, request: data, name: props.order?.request};
          
          console.log('submissionData', props, JSON.stringify(submissionData, null, 2));

          //throw new Error(`Debug Break`);

          var post;
          post = await ApiPost(props.order?.api!, submissionData, props.order?.auth); 
          console.log('post', post);
      /*   
          post = await ApiPost(props.order?.api!, submissionData, props.order?.auth); 
          console.log('post', post);
          post = await ApiPost(props.order?.api!, submissionData, props.order?.auth); 
          console.log('post', post);
          post = await ApiPost(props.order?.api!, submissionData, props.order?.auth); 
          console.log('post', post);
          post = await ApiPost(props.order?.api!, submissionData, props.order?.auth); 
          console.log('post', post);
          post = await ApiPost(props.order?.api!, submissionData, props.order?.auth); 
          console.log('post', post);
          post = await ApiPost(props.order?.api!, submissionData, props.order?.auth); 
          console.log('post', post);
          post = await ApiPost(props.order?.api!, submissionData, props.order?.auth); 
          console.log('post', post);
          post = await ApiPost(props.order?.api!, submissionData, props.order?.auth); 
          console.log('post', post);
          post = await ApiPost(props.order?.api!, submissionData, props.order?.auth); 
          console.log('post', post);

       
          throw new Error(`Debug Break`); // FOR TESTIMG
*/
          console.log('data', data);
          console.log('post', JSON.stringify(post, null, 2));

          if (post.hasOwnProperty('url')) {
            window.location.replace(post.url);
            //window.location.href = post.url;

            console.log('post', JSON.stringify(post, null, 2));
            //setResults(post);
            setFsm(LoadingFsm.FOUND); // never displays because of the above redirection
          }
          else {
            // failed
            window.location.replace(failPath);
            
            setFsm(LoadingFsm.ERROR); // never displays because of the above redirection
          }
        };

      if ((fsm === LoadingFsm.EMPTY) && (Object.keys(data).length !== 0)) { //} && Object.keys(data).length !== 0)  {
        setFsm(LoadingFsm.INITIALISING);
        fetchData();
      }
  }, [data]);
  
  console.log('redraw', fsm, props);

  switch (fsm) {

    case LoadingFsm.EMPTY:
    
      return (
        <> 
            <Pages path={props.path}/>
            
            <EntryForm setRequest={setFormResults} chargeable={props.order?.chargeable!}/> 
            {(props.order?.chargeable) &&
              <div className="center">
                  <img className="stripe" src="/assets/images/stripe.png" alt="Stripe Payments" />
              </div>   
            }
        </>
      )
      break;
    case LoadingFsm.INITIALISING:
    case LoadingFsm.FOUND:
    case LoadingFsm.ERROR:
      return (
        <>
          <br />
          <div className={"d-flex justify-content-center"}>
              <Spinner style={{width: 20, height: 20}} animation="border"></Spinner>
          </div>
        </>
      )
      break;
    default:
      return null;
  }
}
// <PagesMeta path={props.path} />
